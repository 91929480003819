<script setup lang="ts">
import { ApolloClients } from '@vue/apollo-composable'
import { useMainStore } from './store'
import { useCurrentUser, useFirebaseAuth } from 'vuefire'

const auth = useFirebaseAuth()
const user = useCurrentUser()

useHead({
    bodyAttrs: { class: 'bg-white text-black min-h-full' },
    htmlAttrs: { class: 'bg-white' },
    link: [
        {
            rel: 'icon',
            type: 'image/x-icon',
            href: 'https://civiqa.com/favicon.ico',
            sizes: '48x48',
        },
        {
            rel: 'preconnect',
            href: 'https://fonts.googleapi.com',
        },
        {
            rel: 'preconnect',
            href: 'https://fonts.gstatic.com',
            crossorigin: '',
        },
        {
            rel: 'stylesheet',
            href: 'https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap',
        },
    ],

    title: 'Civiqa - Next-generation government intelligence',
})

useSeoMeta({
    title: 'Civiqa - Next-generation government intelligence',
    description: 'Harness government insights for your next big move.',
    ogImage: 'https://civiqa.com/horizontal.png',
    ogUrl: 'https://civiqa.com',
})

// we don't need this watcher on server
onMounted(() => {
    watch(user, async (user, prevUser) => {
        console.log('user changed', user)
        if (prevUser && !user) {
            useMainStore().civiqaUser = null
            await ensureApolloInitialized(null)
            return navigateTo('/')
        } else if (user) {
            await ensureApolloInitialized(user)
        }
    })

    auth?.onIdTokenChanged(async (user) => {
        console.log('id token changed', user)
        if (user) {
            await ensureApolloInitialized(user)
        }
    })

    console.log('mounted')
})

provide(ApolloClients, {
    default: useApollo().clients?.default,
    auth: useApollo().clients?.auth,
})
</script>

<template>
    <div>
        <NuxtLoadingIndicator :height="3" color="#15803d" />
        <NuxtLayout>
            <div>
                <NuxtPage />
            </div>
        </NuxtLayout>
    </div>
</template>

<!-- <style>
html * {
  font-family: "Roboto"
}
</style> -->
