import { default as _91slug_93k6t7kVdB2zMeta } from "/usr/src/app/src/pages/about/blog/[slug].vue?macro=true";
import { default as indexfHOFArswREMeta } from "/usr/src/app/src/pages/about/blog/index.vue?macro=true";
import { default as indexCoiygUgbFKMeta } from "/usr/src/app/src/pages/about/index.vue?macro=true";
import { default as _91id_93sW0UUsVJK6Meta } from "/usr/src/app/src/pages/app/bills/[id].vue?macro=true";
import { default as indexASr8IMR4goMeta } from "/usr/src/app/src/pages/app/bills/search/index.vue?macro=true";
import { default as editloebBr6KDJMeta } from "/usr/src/app/src/pages/app/boards/[slug]/edit.vue?macro=true";
import { default as previewJDXRv69BTnMeta } from "/usr/src/app/src/pages/app/boards/[slug]/preview.vue?macro=true";
import { default as settingsRVSu92Z0q3Meta } from "/usr/src/app/src/pages/app/boards/[slug]/settings.vue?macro=true";
import { default as addsJmXMh5ORaMeta } from "/usr/src/app/src/pages/app/boards/add.vue?macro=true";
import { default as indexBoNODa1g9cMeta } from "/usr/src/app/src/pages/app/boards/index.vue?macro=true";
import { default as bookmarksfw5KJy3rP0Meta } from "/usr/src/app/src/pages/app/bookmarks.vue?macro=true";
import { default as profilehubtXNkqelMeta } from "/usr/src/app/src/pages/app/profile.vue?macro=true";
import { default as threadsUGJIkHRVxwMeta } from "/usr/src/app/src/pages/app/threads.vue?macro=true";
import { default as appJbnS1wSPiBMeta } from "/usr/src/app/src/pages/app.vue?macro=true";
import { default as _91id_93J9jM7L1D5hMeta } from "/usr/src/app/src/pages/bills/[id].vue?macro=true";
import { default as indexhcJT4PbqhZMeta } from "/usr/src/app/src/pages/boards/[slug]/[billboardbillid]/index.vue?macro=true";
import { default as embedwlcL3eROIgMeta } from "/usr/src/app/src/pages/boards/[slug]/embed.vue?macro=true";
import { default as _91id_93TsOxaKl6rhMeta } from "/usr/src/app/src/pages/boards/[slug]/embeds/[id].vue?macro=true";
import { default as indexYg8eVkhjMhMeta } from "/usr/src/app/src/pages/boards/[slug]/index.vue?macro=true";
import { default as coming_45soonf5LRxmZSSYMeta } from "/usr/src/app/src/pages/coming-soon.vue?macro=true";
import { default as contactN8qrkICjM3Meta } from "/usr/src/app/src/pages/contact.vue?macro=true";
import { default as home7WRGGrziepMeta } from "/usr/src/app/src/pages/home.vue?macro=true";
import { default as indexYu3DynwDClMeta } from "/usr/src/app/src/pages/index.vue?macro=true";
import { default as documentst6qFvom5HFMeta } from "/usr/src/app/src/pages/legacy/bills/[session-slug]/[identifier-slug]_[title-slug]/documents.vue?macro=true";
import { default as supportWibJJSKXsBMeta } from "/usr/src/app/src/pages/legacy/bills/[session-slug]/[identifier-slug]_[title-slug]/support.vue?macro=true";
import { default as timelinewK86IncvHXMeta } from "/usr/src/app/src/pages/legacy/bills/[session-slug]/[identifier-slug]_[title-slug]/timeline.vue?macro=true";
import { default as _91id_93qJ1yTxxYiNMeta } from "/usr/src/app/src/pages/legacy/bills/[session-slug]/[identifier-slug]_[title-slug]/versions/[id].vue?macro=true";
import { default as indexRnzqwBdnlRMeta } from "/usr/src/app/src/pages/legacy/bills/[session-slug]/[identifier-slug]_[title-slug]/versions/index.vue?macro=true";
import { default as _91id_93zZzuNQkiPzMeta } from "/usr/src/app/src/pages/legacy/bills/[session-slug]/[identifier-slug]_[title-slug]/votes/[id].vue?macro=true";
import { default as indexVFm6j3UBVfMeta } from "/usr/src/app/src/pages/legacy/bills/[session-slug]/[identifier-slug]_[title-slug]/votes/index.vue?macro=true";
import { default as _91identifier_45slug_93__91title_45slug_93BDhxOZfmaAMeta } from "/usr/src/app/src/pages/legacy/bills/[session-slug]/[identifier-slug]_[title-slug].vue?macro=true";
import { default as bill_45listiKh7ViJBHaMeta } from "/usr/src/app/src/pages/legacy/bills/[session-slug]/session/bill-list.vue?macro=true";
import { default as sponsorshiprb9UPuFbAJMeta } from "/usr/src/app/src/pages/legacy/bills/[session-slug]/session/sponsorship.vue?macro=true";
import { default as votingHY5E6DLLwYMeta } from "/usr/src/app/src/pages/legacy/bills/[session-slug]/session/voting.vue?macro=true";
import { default as sessionpPsG2j6oC7Meta } from "/usr/src/app/src/pages/legacy/bills/[session-slug]/session.vue?macro=true";
import { default as indexzU4Gar8XKiMeta } from "/usr/src/app/src/pages/legacy/bills/index.vue?macro=true";
import { default as searchHk7CDpSxgaMeta } from "/usr/src/app/src/pages/legacy/bills/search.vue?macro=true";
import { default as adminuGx57crIVJMeta } from "/usr/src/app/src/pages/legacy/country:us/district:dc/gov/admin.vue?macro=true";
import { default as latestoM4QCrOI7WMeta } from "/usr/src/app/src/pages/legacy/country:us/district:dc/gov/latest.vue?macro=true";
import { default as govxRrwdcC9u8Meta } from "/usr/src/app/src/pages/legacy/country:us/district:dc/gov.vue?macro=true";
import { default as _91chamber_93KOWnQmyxuSMeta } from "/usr/src/app/src/pages/legacy/country:us/gov/[chamber].vue?macro=true";
import { default as adminZpafM2Js0YMeta } from "/usr/src/app/src/pages/legacy/country:us/gov/admin.vue?macro=true";
import { default as latestRwYGXepJkKMeta } from "/usr/src/app/src/pages/legacy/country:us/gov/latest.vue?macro=true";
import { default as indexNXpcoxrJZZMeta } from "/usr/src/app/src/pages/legacy/country:us/gov/state:[state]/cd:[cd]/index.vue?macro=true";
import { default as indexHi7iDXvHuQMeta } from "/usr/src/app/src/pages/legacy/country:us/gov/state:[state]/index.vue?macro=true";
import { default as govQsNtoZcKXDMeta } from "/usr/src/app/src/pages/legacy/country:us/gov.vue?macro=true";
import { default as _91id_93wQ1oMs7U1UMeta } from "/usr/src/app/src/pages/legacy/country:us/state:[state]/county:[county]/[id].vue?macro=true";
import { default as govaK0Od5RIRfMeta } from "/usr/src/app/src/pages/legacy/country:us/state:[state]/county:[county]/gov.vue?macro=true";
import { default as indexBKCcGgkQhMMeta } from "/usr/src/app/src/pages/legacy/country:us/state:[state]/county:[county]/index.vue?macro=true";
import { default as _91chamber_93ycMw7jfpfwMeta } from "/usr/src/app/src/pages/legacy/country:us/state:[state]/gov/[chamber].vue?macro=true";
import { default as adminbViwjerw3rMeta } from "/usr/src/app/src/pages/legacy/country:us/state:[state]/gov/admin.vue?macro=true";
import { default as latest54dPR9Tn5IMeta } from "/usr/src/app/src/pages/legacy/country:us/state:[state]/gov/latest.vue?macro=true";
import { default as indexnnsVcZ6yoFMeta } from "/usr/src/app/src/pages/legacy/country:us/state:[state]/gov/sldl:[sldl]/index.vue?macro=true";
import { default as indexy64YNVWFfVMeta } from "/usr/src/app/src/pages/legacy/country:us/state:[state]/gov/sldu:[sldu]/index.vue?macro=true";
import { default as govHUT1mN2LwnMeta } from "/usr/src/app/src/pages/legacy/country:us/state:[state]/gov.vue?macro=true";
import { default as indexJMwOBgwx8DMeta } from "/usr/src/app/src/pages/legacy/country:us/state:[state]/index.vue?macro=true";
import { default as govNYcHyHLDIPMeta } from "/usr/src/app/src/pages/legacy/country:us/state:[state]/place:[place]/gov.vue?macro=true";
import { default as country_58usQWGYrwYdI4Meta } from "/usr/src/app/src/pages/legacy/country:us.vue?macro=true";
import { default as indexJTRdPCrBHdMeta } from "/usr/src/app/src/pages/legacy/index.vue?macro=true";
import { default as _91slug_93DUSAmsNjbfMeta } from "/usr/src/app/src/pages/legacy/interests/[slug].vue?macro=true";
import { default as indexPmaOJGCYTgMeta } from "/usr/src/app/src/pages/legacy/interests/index.vue?macro=true";
import { default as iosujxlk0QIXEMeta } from "/usr/src/app/src/pages/legacy/ios.vue?macro=true";
import { default as _91identifier_93sKcUAE25HDMeta } from "/usr/src/app/src/pages/legacy/jurisdictions/[id]/sessions/[identifier].vue?macro=true";
import { default as indexsCKdFFjbyFMeta } from "/usr/src/app/src/pages/legacy/jurisdictions/[id]/sessions/index.vue?macro=true";
import { default as summaryqvUtPZN1PeMeta } from "/usr/src/app/src/pages/legacy/jurisdictions/[id]/summary.vue?macro=true";
import { default as _91id_93vjhYgJPdeyMeta } from "/usr/src/app/src/pages/legacy/jurisdictions/[id].vue?macro=true";
import { default as indexHa2xfvzuRcMeta } from "/usr/src/app/src/pages/legacy/jurisdictions/index.vue?macro=true";
import { default as committeesu396bRQnRYMeta } from "/usr/src/app/src/pages/legacy/persons/[id]/committees.vue?macro=true";
import { default as contactsRVwaj0YgwMeta } from "/usr/src/app/src/pages/legacy/persons/[id]/contact.vue?macro=true";
import { default as sponsorshipsZsdFexag6WMeta } from "/usr/src/app/src/pages/legacy/persons/[id]/sponsorships.vue?macro=true";
import { default as summaryGiUVh3ZDCcMeta } from "/usr/src/app/src/pages/legacy/persons/[id]/summary.vue?macro=true";
import { default as votesf9RBBw4E1GMeta } from "/usr/src/app/src/pages/legacy/persons/[id]/votes.vue?macro=true";
import { default as _91id_93ufq2XnzNlmMeta } from "/usr/src/app/src/pages/legacy/persons/[id].vue?macro=true";
import { default as index0Y4btX3OVFMeta } from "/usr/src/app/src/pages/legacy/persons/index.vue?macro=true";
import { default as stateszTYXs8t9u0Meta } from "/usr/src/app/src/pages/legacy/states.vue?macro=true";
import { default as login2t1QMQfxAxMeta } from "/usr/src/app/src/pages/login.vue?macro=true";
import { default as logoutAhPWW22ABKMeta } from "/usr/src/app/src/pages/logout.vue?macro=true";
import { default as privacyZP7BCLG19uMeta } from "/usr/src/app/src/pages/privacy.vue?macro=true";
import { default as problems7MKDDAVU2SMeta } from "/usr/src/app/src/pages/problems.vue?macro=true";
import { default as signupDLuMX3xyLAMeta } from "/usr/src/app/src/pages/signup.vue?macro=true";
import { default as indexjsNmnhAAJRMeta } from "/usr/src/app/src/pages/solutions/bill-boards/index.vue?macro=true";
import { default as requestnknz9HbDuYMeta } from "/usr/src/app/src/pages/solutions/bill-boards/request.vue?macro=true";
import { default as indexsxghd0jLYqMeta } from "/usr/src/app/src/pages/solutions/index.vue?macro=true";
import { default as indexNKzH9DQY5mMeta } from "/usr/src/app/src/pages/solutions/insights-portal/index.vue?macro=true";
import { default as indexDuczOscAVaMeta } from "/usr/src/app/src/pages/solutions/legislative-associate/index.vue?macro=true";
import { default as support8C3Qrkv7S3Meta } from "/usr/src/app/src/pages/support.vue?macro=true";
import { default as termsA1HF1pYOfSMeta } from "/usr/src/app/src/pages/terms.vue?macro=true";
import { default as verify_45emailtKKyXmdz9CMeta } from "/usr/src/app/src/pages/verify-email.vue?macro=true";
import { default as _91id_93WvJqDxKGk1Meta } from "/usr/src/app/src/pages/widgets/[id].vue?macro=true";
import { default as demo3KqakIzaiwMeta } from "/usr/src/app/src/pages/widgets/demo.vue?macro=true";
export default [
  {
    name: _91slug_93k6t7kVdB2zMeta?.name ?? "about-blog-slug",
    path: _91slug_93k6t7kVdB2zMeta?.path ?? "/about/blog/:slug()",
    meta: _91slug_93k6t7kVdB2zMeta || {},
    alias: _91slug_93k6t7kVdB2zMeta?.alias || [],
    redirect: _91slug_93k6t7kVdB2zMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/about/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexfHOFArswREMeta?.name ?? "about-blog",
    path: indexfHOFArswREMeta?.path ?? "/about/blog",
    meta: indexfHOFArswREMeta || {},
    alias: indexfHOFArswREMeta?.alias || [],
    redirect: indexfHOFArswREMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/about/blog/index.vue").then(m => m.default || m)
  },
  {
    name: indexCoiygUgbFKMeta?.name ?? "about",
    path: indexCoiygUgbFKMeta?.path ?? "/about",
    meta: indexCoiygUgbFKMeta || {},
    alias: indexCoiygUgbFKMeta?.alias || [],
    redirect: indexCoiygUgbFKMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/about/index.vue").then(m => m.default || m)
  },
  {
    name: appJbnS1wSPiBMeta?.name ?? "app",
    path: appJbnS1wSPiBMeta?.path ?? "/app",
    meta: appJbnS1wSPiBMeta || {},
    alias: appJbnS1wSPiBMeta?.alias || [],
    redirect: appJbnS1wSPiBMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/app.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_93sW0UUsVJK6Meta?.name ?? "app-bills-id",
    path: _91id_93sW0UUsVJK6Meta?.path ?? "bills/:id()",
    meta: _91id_93sW0UUsVJK6Meta || {},
    alias: _91id_93sW0UUsVJK6Meta?.alias || [],
    redirect: _91id_93sW0UUsVJK6Meta?.redirect,
    component: () => import("/usr/src/app/src/pages/app/bills/[id].vue").then(m => m.default || m)
  },
  {
    name: indexASr8IMR4goMeta?.name ?? "app-bills-search",
    path: indexASr8IMR4goMeta?.path ?? "bills/search",
    meta: indexASr8IMR4goMeta || {},
    alias: indexASr8IMR4goMeta?.alias || [],
    redirect: indexASr8IMR4goMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/app/bills/search/index.vue").then(m => m.default || m)
  },
  {
    name: editloebBr6KDJMeta?.name ?? "app-boards-slug-edit",
    path: editloebBr6KDJMeta?.path ?? "boards/:slug()/edit",
    meta: editloebBr6KDJMeta || {},
    alias: editloebBr6KDJMeta?.alias || [],
    redirect: editloebBr6KDJMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/app/boards/[slug]/edit.vue").then(m => m.default || m)
  },
  {
    name: previewJDXRv69BTnMeta?.name ?? "app-boards-slug-preview",
    path: previewJDXRv69BTnMeta?.path ?? "boards/:slug()/preview",
    meta: previewJDXRv69BTnMeta || {},
    alias: previewJDXRv69BTnMeta?.alias || [],
    redirect: previewJDXRv69BTnMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/app/boards/[slug]/preview.vue").then(m => m.default || m)
  },
  {
    name: settingsRVSu92Z0q3Meta?.name ?? "app-boards-slug-settings",
    path: settingsRVSu92Z0q3Meta?.path ?? "boards/:slug()/settings",
    meta: settingsRVSu92Z0q3Meta || {},
    alias: settingsRVSu92Z0q3Meta?.alias || [],
    redirect: settingsRVSu92Z0q3Meta?.redirect,
    component: () => import("/usr/src/app/src/pages/app/boards/[slug]/settings.vue").then(m => m.default || m)
  },
  {
    name: addsJmXMh5ORaMeta?.name ?? "app-boards-add",
    path: addsJmXMh5ORaMeta?.path ?? "boards/add",
    meta: addsJmXMh5ORaMeta || {},
    alias: addsJmXMh5ORaMeta?.alias || [],
    redirect: addsJmXMh5ORaMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/app/boards/add.vue").then(m => m.default || m)
  },
  {
    name: indexBoNODa1g9cMeta?.name ?? "app-boards",
    path: indexBoNODa1g9cMeta?.path ?? "boards",
    meta: indexBoNODa1g9cMeta || {},
    alias: indexBoNODa1g9cMeta?.alias || [],
    redirect: indexBoNODa1g9cMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/app/boards/index.vue").then(m => m.default || m)
  },
  {
    name: bookmarksfw5KJy3rP0Meta?.name ?? "app-bookmarks",
    path: bookmarksfw5KJy3rP0Meta?.path ?? "bookmarks",
    meta: bookmarksfw5KJy3rP0Meta || {},
    alias: bookmarksfw5KJy3rP0Meta?.alias || [],
    redirect: bookmarksfw5KJy3rP0Meta?.redirect,
    component: () => import("/usr/src/app/src/pages/app/bookmarks.vue").then(m => m.default || m)
  },
  {
    name: profilehubtXNkqelMeta?.name ?? "app-profile",
    path: profilehubtXNkqelMeta?.path ?? "profile",
    meta: profilehubtXNkqelMeta || {},
    alias: profilehubtXNkqelMeta?.alias || [],
    redirect: profilehubtXNkqelMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/app/profile.vue").then(m => m.default || m)
  },
  {
    name: threadsUGJIkHRVxwMeta?.name ?? "app-threads",
    path: threadsUGJIkHRVxwMeta?.path ?? "threads",
    meta: threadsUGJIkHRVxwMeta || {},
    alias: threadsUGJIkHRVxwMeta?.alias || [],
    redirect: threadsUGJIkHRVxwMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/app/threads.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91id_93J9jM7L1D5hMeta?.name ?? "bills-id",
    path: _91id_93J9jM7L1D5hMeta?.path ?? "/bills/:id()",
    meta: _91id_93J9jM7L1D5hMeta || {},
    alias: _91id_93J9jM7L1D5hMeta?.alias || [],
    redirect: _91id_93J9jM7L1D5hMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/bills/[id].vue").then(m => m.default || m)
  },
  {
    name: indexhcJT4PbqhZMeta?.name ?? "boards-slug-billboardbillid",
    path: indexhcJT4PbqhZMeta?.path ?? "/boards/:slug()/:billboardbillid()",
    meta: indexhcJT4PbqhZMeta || {},
    alias: indexhcJT4PbqhZMeta?.alias || [],
    redirect: indexhcJT4PbqhZMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/boards/[slug]/[billboardbillid]/index.vue").then(m => m.default || m)
  },
  {
    name: embedwlcL3eROIgMeta?.name ?? "boards-slug-embed",
    path: embedwlcL3eROIgMeta?.path ?? "/boards/:slug()/embed",
    meta: embedwlcL3eROIgMeta || {},
    alias: embedwlcL3eROIgMeta?.alias || [],
    redirect: embedwlcL3eROIgMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/boards/[slug]/embed.vue").then(m => m.default || m)
  },
  {
    name: _91id_93TsOxaKl6rhMeta?.name ?? "boards-slug-embeds-id",
    path: _91id_93TsOxaKl6rhMeta?.path ?? "/boards/:slug()/embeds/:id()",
    meta: _91id_93TsOxaKl6rhMeta || {},
    alias: _91id_93TsOxaKl6rhMeta?.alias || [],
    redirect: _91id_93TsOxaKl6rhMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/boards/[slug]/embeds/[id].vue").then(m => m.default || m)
  },
  {
    name: indexYg8eVkhjMhMeta?.name ?? "boards-slug",
    path: indexYg8eVkhjMhMeta?.path ?? "/boards/:slug()",
    meta: indexYg8eVkhjMhMeta || {},
    alias: indexYg8eVkhjMhMeta?.alias || [],
    redirect: indexYg8eVkhjMhMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/boards/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: coming_45soonf5LRxmZSSYMeta?.name ?? "coming-soon",
    path: coming_45soonf5LRxmZSSYMeta?.path ?? "/coming-soon",
    meta: coming_45soonf5LRxmZSSYMeta || {},
    alias: coming_45soonf5LRxmZSSYMeta?.alias || [],
    redirect: coming_45soonf5LRxmZSSYMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/coming-soon.vue").then(m => m.default || m)
  },
  {
    name: contactN8qrkICjM3Meta?.name ?? "contact",
    path: contactN8qrkICjM3Meta?.path ?? "/contact",
    meta: contactN8qrkICjM3Meta || {},
    alias: contactN8qrkICjM3Meta?.alias || [],
    redirect: contactN8qrkICjM3Meta?.redirect,
    component: () => import("/usr/src/app/src/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: home7WRGGrziepMeta?.name ?? "home",
    path: home7WRGGrziepMeta?.path ?? "/home",
    meta: home7WRGGrziepMeta || {},
    alias: home7WRGGrziepMeta?.alias || [],
    redirect: home7WRGGrziepMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/home.vue").then(m => m.default || m)
  },
  {
    name: indexYu3DynwDClMeta?.name ?? "index",
    path: indexYu3DynwDClMeta?.path ?? "/",
    meta: indexYu3DynwDClMeta || {},
    alias: indexYu3DynwDClMeta?.alias || [],
    redirect: indexYu3DynwDClMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91identifier_45slug_93__91title_45slug_93BDhxOZfmaAMeta?.name ?? "legacy-bills-sessionslug-identifierslug_titleslug",
    path: _91identifier_45slug_93__91title_45slug_93BDhxOZfmaAMeta?.path ?? "/legacy/bills/:sessionslug()/:identifierslug()_:titleslug()",
    meta: _91identifier_45slug_93__91title_45slug_93BDhxOZfmaAMeta || {},
    alias: _91identifier_45slug_93__91title_45slug_93BDhxOZfmaAMeta?.alias || [],
    redirect: _91identifier_45slug_93__91title_45slug_93BDhxOZfmaAMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/bills/[session-slug]/[identifier-slug]_[title-slug].vue").then(m => m.default || m),
    children: [
  {
    name: documentst6qFvom5HFMeta?.name ?? "legacy-bills-sessionslug-identifierslug_titleslug-documents",
    path: documentst6qFvom5HFMeta?.path ?? "documents",
    meta: documentst6qFvom5HFMeta || {},
    alias: documentst6qFvom5HFMeta?.alias || [],
    redirect: documentst6qFvom5HFMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/bills/[session-slug]/[identifier-slug]_[title-slug]/documents.vue").then(m => m.default || m)
  },
  {
    name: supportWibJJSKXsBMeta?.name ?? "legacy-bills-sessionslug-identifierslug_titleslug-support",
    path: supportWibJJSKXsBMeta?.path ?? "support",
    meta: supportWibJJSKXsBMeta || {},
    alias: supportWibJJSKXsBMeta?.alias || [],
    redirect: supportWibJJSKXsBMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/bills/[session-slug]/[identifier-slug]_[title-slug]/support.vue").then(m => m.default || m)
  },
  {
    name: timelinewK86IncvHXMeta?.name ?? "legacy-bills-sessionslug-identifierslug_titleslug-timeline",
    path: timelinewK86IncvHXMeta?.path ?? "timeline",
    meta: timelinewK86IncvHXMeta || {},
    alias: timelinewK86IncvHXMeta?.alias || [],
    redirect: timelinewK86IncvHXMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/bills/[session-slug]/[identifier-slug]_[title-slug]/timeline.vue").then(m => m.default || m)
  },
  {
    name: _91id_93qJ1yTxxYiNMeta?.name ?? "legacy-bills-sessionslug-identifierslug_titleslug-versions-id",
    path: _91id_93qJ1yTxxYiNMeta?.path ?? "versions/:id()",
    meta: _91id_93qJ1yTxxYiNMeta || {},
    alias: _91id_93qJ1yTxxYiNMeta?.alias || [],
    redirect: _91id_93qJ1yTxxYiNMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/bills/[session-slug]/[identifier-slug]_[title-slug]/versions/[id].vue").then(m => m.default || m)
  },
  {
    name: indexRnzqwBdnlRMeta?.name ?? "legacy-bills-sessionslug-identifierslug_titleslug-versions",
    path: indexRnzqwBdnlRMeta?.path ?? "versions",
    meta: indexRnzqwBdnlRMeta || {},
    alias: indexRnzqwBdnlRMeta?.alias || [],
    redirect: indexRnzqwBdnlRMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/bills/[session-slug]/[identifier-slug]_[title-slug]/versions/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93zZzuNQkiPzMeta?.name ?? "legacy-bills-sessionslug-identifierslug_titleslug-votes-id",
    path: _91id_93zZzuNQkiPzMeta?.path ?? "votes/:id()",
    meta: _91id_93zZzuNQkiPzMeta || {},
    alias: _91id_93zZzuNQkiPzMeta?.alias || [],
    redirect: _91id_93zZzuNQkiPzMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/bills/[session-slug]/[identifier-slug]_[title-slug]/votes/[id].vue").then(m => m.default || m)
  },
  {
    name: indexVFm6j3UBVfMeta?.name ?? "legacy-bills-sessionslug-identifierslug_titleslug-votes",
    path: indexVFm6j3UBVfMeta?.path ?? "votes",
    meta: indexVFm6j3UBVfMeta || {},
    alias: indexVFm6j3UBVfMeta?.alias || [],
    redirect: indexVFm6j3UBVfMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/bills/[session-slug]/[identifier-slug]_[title-slug]/votes/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: sessionpPsG2j6oC7Meta?.name ?? "legacy-bills-sessionslug-session",
    path: sessionpPsG2j6oC7Meta?.path ?? "/legacy/bills/:sessionslug()/session",
    meta: sessionpPsG2j6oC7Meta || {},
    alias: sessionpPsG2j6oC7Meta?.alias || [],
    redirect: sessionpPsG2j6oC7Meta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/bills/[session-slug]/session.vue").then(m => m.default || m),
    children: [
  {
    name: bill_45listiKh7ViJBHaMeta?.name ?? "legacy-bills-sessionslug-session-bill-list",
    path: bill_45listiKh7ViJBHaMeta?.path ?? "bill-list",
    meta: bill_45listiKh7ViJBHaMeta || {},
    alias: bill_45listiKh7ViJBHaMeta?.alias || [],
    redirect: bill_45listiKh7ViJBHaMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/bills/[session-slug]/session/bill-list.vue").then(m => m.default || m)
  },
  {
    name: sponsorshiprb9UPuFbAJMeta?.name ?? "legacy-bills-sessionslug-session-sponsorship",
    path: sponsorshiprb9UPuFbAJMeta?.path ?? "sponsorship",
    meta: sponsorshiprb9UPuFbAJMeta || {},
    alias: sponsorshiprb9UPuFbAJMeta?.alias || [],
    redirect: sponsorshiprb9UPuFbAJMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/bills/[session-slug]/session/sponsorship.vue").then(m => m.default || m)
  },
  {
    name: votingHY5E6DLLwYMeta?.name ?? "legacy-bills-sessionslug-session-voting",
    path: votingHY5E6DLLwYMeta?.path ?? "voting",
    meta: votingHY5E6DLLwYMeta || {},
    alias: votingHY5E6DLLwYMeta?.alias || [],
    redirect: votingHY5E6DLLwYMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/bills/[session-slug]/session/voting.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexzU4Gar8XKiMeta?.name ?? "legacy-bills",
    path: indexzU4Gar8XKiMeta?.path ?? "/legacy/bills",
    meta: indexzU4Gar8XKiMeta || {},
    alias: indexzU4Gar8XKiMeta?.alias || [],
    redirect: indexzU4Gar8XKiMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/bills/index.vue").then(m => m.default || m)
  },
  {
    name: searchHk7CDpSxgaMeta?.name ?? "legacy-bills-search",
    path: searchHk7CDpSxgaMeta?.path ?? "/legacy/bills/search",
    meta: searchHk7CDpSxgaMeta || {},
    alias: searchHk7CDpSxgaMeta?.alias || [],
    redirect: searchHk7CDpSxgaMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/bills/search.vue").then(m => m.default || m)
  },
  {
    name: country_58usQWGYrwYdI4Meta?.name ?? "legacy-country:us",
    path: country_58usQWGYrwYdI4Meta?.path ?? "/legacy/country\\:us",
    meta: country_58usQWGYrwYdI4Meta || {},
    alias: country_58usQWGYrwYdI4Meta?.alias || [],
    redirect: country_58usQWGYrwYdI4Meta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/country:us.vue").then(m => m.default || m),
    children: [
  {
    name: govxRrwdcC9u8Meta?.name ?? "legacy-country:us-district:dc-gov",
    path: govxRrwdcC9u8Meta?.path ?? "district\\:dc/gov",
    meta: govxRrwdcC9u8Meta || {},
    alias: govxRrwdcC9u8Meta?.alias || [],
    redirect: govxRrwdcC9u8Meta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/country:us/district:dc/gov.vue").then(m => m.default || m),
    children: [
  {
    name: adminuGx57crIVJMeta?.name ?? "legacy-country:us-district:dc-gov-admin",
    path: adminuGx57crIVJMeta?.path ?? "admin",
    meta: adminuGx57crIVJMeta || {},
    alias: adminuGx57crIVJMeta?.alias || [],
    redirect: adminuGx57crIVJMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/country:us/district:dc/gov/admin.vue").then(m => m.default || m)
  },
  {
    name: latestoM4QCrOI7WMeta?.name ?? "legacy-country:us-district:dc-gov-latest",
    path: latestoM4QCrOI7WMeta?.path ?? "latest",
    meta: latestoM4QCrOI7WMeta || {},
    alias: latestoM4QCrOI7WMeta?.alias || [],
    redirect: latestoM4QCrOI7WMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/country:us/district:dc/gov/latest.vue").then(m => m.default || m)
  },
  {
    name: _91identifier_45slug_93__91title_45slug_93BDhxOZfmaAMeta?.name ?? "legacy-country:us-district:dc-gov-legacy-bills-sessionslug-identifierslug_titleslug",
    path: _91identifier_45slug_93__91title_45slug_93BDhxOZfmaAMeta?.path ?? "bills/:sessionslug()/:identifierslug()_:titleslug()",
    meta: _91identifier_45slug_93__91title_45slug_93BDhxOZfmaAMeta || {},
    alias: _91identifier_45slug_93__91title_45slug_93BDhxOZfmaAMeta?.alias || [],
    redirect: _91identifier_45slug_93__91title_45slug_93BDhxOZfmaAMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/bills/[session-slug]/[identifier-slug]_[title-slug].vue").then(m => m.default || m),
    children: [
  {
    name: documentst6qFvom5HFMeta?.name ?? "legacy-country:us-district:dc-gov-legacy-bills-sessionslug-identifierslug_titleslug-documents",
    path: documentst6qFvom5HFMeta?.path ?? "documents",
    meta: documentst6qFvom5HFMeta || {},
    alias: documentst6qFvom5HFMeta?.alias || [],
    redirect: documentst6qFvom5HFMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/bills/[session-slug]/[identifier-slug]_[title-slug]/documents.vue").then(m => m.default || m)
  },
  {
    name: supportWibJJSKXsBMeta?.name ?? "legacy-country:us-district:dc-gov-legacy-bills-sessionslug-identifierslug_titleslug-support",
    path: supportWibJJSKXsBMeta?.path ?? "support",
    meta: supportWibJJSKXsBMeta || {},
    alias: supportWibJJSKXsBMeta?.alias || [],
    redirect: supportWibJJSKXsBMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/bills/[session-slug]/[identifier-slug]_[title-slug]/support.vue").then(m => m.default || m)
  },
  {
    name: timelinewK86IncvHXMeta?.name ?? "legacy-country:us-district:dc-gov-legacy-bills-sessionslug-identifierslug_titleslug-timeline",
    path: timelinewK86IncvHXMeta?.path ?? "timeline",
    meta: timelinewK86IncvHXMeta || {},
    alias: timelinewK86IncvHXMeta?.alias || [],
    redirect: timelinewK86IncvHXMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/bills/[session-slug]/[identifier-slug]_[title-slug]/timeline.vue").then(m => m.default || m)
  },
  {
    name: _91id_93qJ1yTxxYiNMeta?.name ?? "legacy-country:us-district:dc-gov-legacy-bills-sessionslug-identifierslug_titleslug-versions-id",
    path: _91id_93qJ1yTxxYiNMeta?.path ?? "versions/:id()",
    meta: _91id_93qJ1yTxxYiNMeta || {},
    alias: _91id_93qJ1yTxxYiNMeta?.alias || [],
    redirect: _91id_93qJ1yTxxYiNMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/bills/[session-slug]/[identifier-slug]_[title-slug]/versions/[id].vue").then(m => m.default || m)
  },
  {
    name: indexRnzqwBdnlRMeta?.name ?? "legacy-country:us-district:dc-gov-legacy-bills-sessionslug-identifierslug_titleslug-versions",
    path: indexRnzqwBdnlRMeta?.path ?? "versions",
    meta: indexRnzqwBdnlRMeta || {},
    alias: indexRnzqwBdnlRMeta?.alias || [],
    redirect: indexRnzqwBdnlRMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/bills/[session-slug]/[identifier-slug]_[title-slug]/versions/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93zZzuNQkiPzMeta?.name ?? "legacy-country:us-district:dc-gov-legacy-bills-sessionslug-identifierslug_titleslug-votes-id",
    path: _91id_93zZzuNQkiPzMeta?.path ?? "votes/:id()",
    meta: _91id_93zZzuNQkiPzMeta || {},
    alias: _91id_93zZzuNQkiPzMeta?.alias || [],
    redirect: _91id_93zZzuNQkiPzMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/bills/[session-slug]/[identifier-slug]_[title-slug]/votes/[id].vue").then(m => m.default || m)
  },
  {
    name: indexVFm6j3UBVfMeta?.name ?? "legacy-country:us-district:dc-gov-legacy-bills-sessionslug-identifierslug_titleslug-votes",
    path: indexVFm6j3UBVfMeta?.path ?? "votes",
    meta: indexVFm6j3UBVfMeta || {},
    alias: indexVFm6j3UBVfMeta?.alias || [],
    redirect: indexVFm6j3UBVfMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/bills/[session-slug]/[identifier-slug]_[title-slug]/votes/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: sessionpPsG2j6oC7Meta?.name ?? "legacy-country:us-district:dc-gov-legacy-bills-sessionslug-session",
    path: sessionpPsG2j6oC7Meta?.path ?? "bills/:sessionslug()/session",
    meta: sessionpPsG2j6oC7Meta || {},
    alias: sessionpPsG2j6oC7Meta?.alias || [],
    redirect: sessionpPsG2j6oC7Meta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/bills/[session-slug]/session.vue").then(m => m.default || m),
    children: [
  {
    name: bill_45listiKh7ViJBHaMeta?.name ?? "legacy-country:us-district:dc-gov-legacy-bills-sessionslug-session-bill-list",
    path: bill_45listiKh7ViJBHaMeta?.path ?? "bill-list",
    meta: bill_45listiKh7ViJBHaMeta || {},
    alias: bill_45listiKh7ViJBHaMeta?.alias || [],
    redirect: bill_45listiKh7ViJBHaMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/bills/[session-slug]/session/bill-list.vue").then(m => m.default || m)
  },
  {
    name: sponsorshiprb9UPuFbAJMeta?.name ?? "legacy-country:us-district:dc-gov-legacy-bills-sessionslug-session-sponsorship",
    path: sponsorshiprb9UPuFbAJMeta?.path ?? "sponsorship",
    meta: sponsorshiprb9UPuFbAJMeta || {},
    alias: sponsorshiprb9UPuFbAJMeta?.alias || [],
    redirect: sponsorshiprb9UPuFbAJMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/bills/[session-slug]/session/sponsorship.vue").then(m => m.default || m)
  },
  {
    name: votingHY5E6DLLwYMeta?.name ?? "legacy-country:us-district:dc-gov-legacy-bills-sessionslug-session-voting",
    path: votingHY5E6DLLwYMeta?.path ?? "voting",
    meta: votingHY5E6DLLwYMeta || {},
    alias: votingHY5E6DLLwYMeta?.alias || [],
    redirect: votingHY5E6DLLwYMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/bills/[session-slug]/session/voting.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexzU4Gar8XKiMeta?.name ?? "legacy-country:us-district:dc-gov-legacy-bills",
    path: indexzU4Gar8XKiMeta?.path ?? "bills",
    meta: indexzU4Gar8XKiMeta || {},
    alias: indexzU4Gar8XKiMeta?.alias || [],
    redirect: indexzU4Gar8XKiMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/bills/index.vue").then(m => m.default || m)
  },
  {
    name: searchHk7CDpSxgaMeta?.name ?? "legacy-country:us-district:dc-gov-legacy-bills-search",
    path: searchHk7CDpSxgaMeta?.path ?? "bills/search",
    meta: searchHk7CDpSxgaMeta || {},
    alias: searchHk7CDpSxgaMeta?.alias || [],
    redirect: searchHk7CDpSxgaMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/bills/search.vue").then(m => m.default || m)
  }
]
  },
  {
    name: govQsNtoZcKXDMeta?.name ?? "legacy-country:us-gov",
    path: govQsNtoZcKXDMeta?.path ?? "gov",
    meta: govQsNtoZcKXDMeta || {},
    alias: govQsNtoZcKXDMeta?.alias || [],
    redirect: govQsNtoZcKXDMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/country:us/gov.vue").then(m => m.default || m),
    children: [
  {
    name: _91chamber_93KOWnQmyxuSMeta?.name ?? "legacy-country:us-gov-chamber",
    path: _91chamber_93KOWnQmyxuSMeta?.path ?? ":chamber()",
    meta: _91chamber_93KOWnQmyxuSMeta || {},
    alias: _91chamber_93KOWnQmyxuSMeta?.alias || [],
    redirect: _91chamber_93KOWnQmyxuSMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/country:us/gov/[chamber].vue").then(m => m.default || m)
  },
  {
    name: adminZpafM2Js0YMeta?.name ?? "legacy-country:us-gov-admin",
    path: adminZpafM2Js0YMeta?.path ?? "admin",
    meta: adminZpafM2Js0YMeta || {},
    alias: adminZpafM2Js0YMeta?.alias || [],
    redirect: adminZpafM2Js0YMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/country:us/gov/admin.vue").then(m => m.default || m)
  },
  {
    name: latestRwYGXepJkKMeta?.name ?? "legacy-country:us-gov-latest",
    path: latestRwYGXepJkKMeta?.path ?? "latest",
    meta: latestRwYGXepJkKMeta || {},
    alias: latestRwYGXepJkKMeta?.alias || [],
    redirect: latestRwYGXepJkKMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/country:us/gov/latest.vue").then(m => m.default || m)
  },
  {
    name: indexNXpcoxrJZZMeta?.name ?? "legacy-country:us-gov-state:state-cd:cd",
    path: indexNXpcoxrJZZMeta?.path ?? "state\\::state()/cd\\::cd()",
    meta: indexNXpcoxrJZZMeta || {},
    alias: indexNXpcoxrJZZMeta?.alias || [],
    redirect: indexNXpcoxrJZZMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/country:us/gov/state:[state]/cd:[cd]/index.vue").then(m => m.default || m)
  },
  {
    name: indexHi7iDXvHuQMeta?.name ?? "legacy-country:us-gov-state:state",
    path: indexHi7iDXvHuQMeta?.path ?? "state\\::state()",
    meta: indexHi7iDXvHuQMeta || {},
    alias: indexHi7iDXvHuQMeta?.alias || [],
    redirect: indexHi7iDXvHuQMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/country:us/gov/state:[state]/index.vue").then(m => m.default || m)
  },
  {
    name: _91identifier_45slug_93__91title_45slug_93BDhxOZfmaAMeta?.name ?? "legacy-country:us-gov-legacy-bills-sessionslug-identifierslug_titleslug",
    path: _91identifier_45slug_93__91title_45slug_93BDhxOZfmaAMeta?.path ?? "bills/:sessionslug()/:identifierslug()_:titleslug()",
    meta: _91identifier_45slug_93__91title_45slug_93BDhxOZfmaAMeta || {},
    alias: _91identifier_45slug_93__91title_45slug_93BDhxOZfmaAMeta?.alias || [],
    redirect: _91identifier_45slug_93__91title_45slug_93BDhxOZfmaAMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/bills/[session-slug]/[identifier-slug]_[title-slug].vue").then(m => m.default || m),
    children: [
  {
    name: documentst6qFvom5HFMeta?.name ?? "legacy-country:us-gov-legacy-bills-sessionslug-identifierslug_titleslug-documents",
    path: documentst6qFvom5HFMeta?.path ?? "documents",
    meta: documentst6qFvom5HFMeta || {},
    alias: documentst6qFvom5HFMeta?.alias || [],
    redirect: documentst6qFvom5HFMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/bills/[session-slug]/[identifier-slug]_[title-slug]/documents.vue").then(m => m.default || m)
  },
  {
    name: supportWibJJSKXsBMeta?.name ?? "legacy-country:us-gov-legacy-bills-sessionslug-identifierslug_titleslug-support",
    path: supportWibJJSKXsBMeta?.path ?? "support",
    meta: supportWibJJSKXsBMeta || {},
    alias: supportWibJJSKXsBMeta?.alias || [],
    redirect: supportWibJJSKXsBMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/bills/[session-slug]/[identifier-slug]_[title-slug]/support.vue").then(m => m.default || m)
  },
  {
    name: timelinewK86IncvHXMeta?.name ?? "legacy-country:us-gov-legacy-bills-sessionslug-identifierslug_titleslug-timeline",
    path: timelinewK86IncvHXMeta?.path ?? "timeline",
    meta: timelinewK86IncvHXMeta || {},
    alias: timelinewK86IncvHXMeta?.alias || [],
    redirect: timelinewK86IncvHXMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/bills/[session-slug]/[identifier-slug]_[title-slug]/timeline.vue").then(m => m.default || m)
  },
  {
    name: _91id_93qJ1yTxxYiNMeta?.name ?? "legacy-country:us-gov-legacy-bills-sessionslug-identifierslug_titleslug-versions-id",
    path: _91id_93qJ1yTxxYiNMeta?.path ?? "versions/:id()",
    meta: _91id_93qJ1yTxxYiNMeta || {},
    alias: _91id_93qJ1yTxxYiNMeta?.alias || [],
    redirect: _91id_93qJ1yTxxYiNMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/bills/[session-slug]/[identifier-slug]_[title-slug]/versions/[id].vue").then(m => m.default || m)
  },
  {
    name: indexRnzqwBdnlRMeta?.name ?? "legacy-country:us-gov-legacy-bills-sessionslug-identifierslug_titleslug-versions",
    path: indexRnzqwBdnlRMeta?.path ?? "versions",
    meta: indexRnzqwBdnlRMeta || {},
    alias: indexRnzqwBdnlRMeta?.alias || [],
    redirect: indexRnzqwBdnlRMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/bills/[session-slug]/[identifier-slug]_[title-slug]/versions/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93zZzuNQkiPzMeta?.name ?? "legacy-country:us-gov-legacy-bills-sessionslug-identifierslug_titleslug-votes-id",
    path: _91id_93zZzuNQkiPzMeta?.path ?? "votes/:id()",
    meta: _91id_93zZzuNQkiPzMeta || {},
    alias: _91id_93zZzuNQkiPzMeta?.alias || [],
    redirect: _91id_93zZzuNQkiPzMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/bills/[session-slug]/[identifier-slug]_[title-slug]/votes/[id].vue").then(m => m.default || m)
  },
  {
    name: indexVFm6j3UBVfMeta?.name ?? "legacy-country:us-gov-legacy-bills-sessionslug-identifierslug_titleslug-votes",
    path: indexVFm6j3UBVfMeta?.path ?? "votes",
    meta: indexVFm6j3UBVfMeta || {},
    alias: indexVFm6j3UBVfMeta?.alias || [],
    redirect: indexVFm6j3UBVfMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/bills/[session-slug]/[identifier-slug]_[title-slug]/votes/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: sessionpPsG2j6oC7Meta?.name ?? "legacy-country:us-gov-legacy-bills-sessionslug-session",
    path: sessionpPsG2j6oC7Meta?.path ?? "bills/:sessionslug()/session",
    meta: sessionpPsG2j6oC7Meta || {},
    alias: sessionpPsG2j6oC7Meta?.alias || [],
    redirect: sessionpPsG2j6oC7Meta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/bills/[session-slug]/session.vue").then(m => m.default || m),
    children: [
  {
    name: bill_45listiKh7ViJBHaMeta?.name ?? "legacy-country:us-gov-legacy-bills-sessionslug-session-bill-list",
    path: bill_45listiKh7ViJBHaMeta?.path ?? "bill-list",
    meta: bill_45listiKh7ViJBHaMeta || {},
    alias: bill_45listiKh7ViJBHaMeta?.alias || [],
    redirect: bill_45listiKh7ViJBHaMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/bills/[session-slug]/session/bill-list.vue").then(m => m.default || m)
  },
  {
    name: sponsorshiprb9UPuFbAJMeta?.name ?? "legacy-country:us-gov-legacy-bills-sessionslug-session-sponsorship",
    path: sponsorshiprb9UPuFbAJMeta?.path ?? "sponsorship",
    meta: sponsorshiprb9UPuFbAJMeta || {},
    alias: sponsorshiprb9UPuFbAJMeta?.alias || [],
    redirect: sponsorshiprb9UPuFbAJMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/bills/[session-slug]/session/sponsorship.vue").then(m => m.default || m)
  },
  {
    name: votingHY5E6DLLwYMeta?.name ?? "legacy-country:us-gov-legacy-bills-sessionslug-session-voting",
    path: votingHY5E6DLLwYMeta?.path ?? "voting",
    meta: votingHY5E6DLLwYMeta || {},
    alias: votingHY5E6DLLwYMeta?.alias || [],
    redirect: votingHY5E6DLLwYMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/bills/[session-slug]/session/voting.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexzU4Gar8XKiMeta?.name ?? "legacy-country:us-gov-legacy-bills",
    path: indexzU4Gar8XKiMeta?.path ?? "bills",
    meta: indexzU4Gar8XKiMeta || {},
    alias: indexzU4Gar8XKiMeta?.alias || [],
    redirect: indexzU4Gar8XKiMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/bills/index.vue").then(m => m.default || m)
  },
  {
    name: searchHk7CDpSxgaMeta?.name ?? "legacy-country:us-gov-legacy-bills-search",
    path: searchHk7CDpSxgaMeta?.path ?? "bills/search",
    meta: searchHk7CDpSxgaMeta || {},
    alias: searchHk7CDpSxgaMeta?.alias || [],
    redirect: searchHk7CDpSxgaMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/bills/search.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91id_93wQ1oMs7U1UMeta?.name ?? "legacy-country:us-state:state-county:county-id",
    path: _91id_93wQ1oMs7U1UMeta?.path ?? "state\\::state()/county\\::county()/:id()",
    meta: _91id_93wQ1oMs7U1UMeta || {},
    alias: _91id_93wQ1oMs7U1UMeta?.alias || [],
    redirect: _91id_93wQ1oMs7U1UMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/country:us/state:[state]/county:[county]/[id].vue").then(m => m.default || m)
  },
  {
    name: govaK0Od5RIRfMeta?.name ?? "legacy-country:us-state:state-county:county-gov",
    path: govaK0Od5RIRfMeta?.path ?? "state\\::state()/county\\::county()/gov",
    meta: govaK0Od5RIRfMeta || {},
    alias: govaK0Od5RIRfMeta?.alias || [],
    redirect: govaK0Od5RIRfMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/country:us/state:[state]/county:[county]/gov.vue").then(m => m.default || m),
    children: [
  {
    name: _91identifier_45slug_93__91title_45slug_93BDhxOZfmaAMeta?.name ?? "legacy-country:us-state:state-county:county-gov-legacy-bills-sessionslug-identifierslug_titleslug",
    path: _91identifier_45slug_93__91title_45slug_93BDhxOZfmaAMeta?.path ?? "bills/:sessionslug()/:identifierslug()_:titleslug()",
    meta: _91identifier_45slug_93__91title_45slug_93BDhxOZfmaAMeta || {},
    alias: _91identifier_45slug_93__91title_45slug_93BDhxOZfmaAMeta?.alias || [],
    redirect: _91identifier_45slug_93__91title_45slug_93BDhxOZfmaAMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/bills/[session-slug]/[identifier-slug]_[title-slug].vue").then(m => m.default || m),
    children: [
  {
    name: documentst6qFvom5HFMeta?.name ?? "legacy-country:us-state:state-county:county-gov-legacy-bills-sessionslug-identifierslug_titleslug-documents",
    path: documentst6qFvom5HFMeta?.path ?? "documents",
    meta: documentst6qFvom5HFMeta || {},
    alias: documentst6qFvom5HFMeta?.alias || [],
    redirect: documentst6qFvom5HFMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/bills/[session-slug]/[identifier-slug]_[title-slug]/documents.vue").then(m => m.default || m)
  },
  {
    name: supportWibJJSKXsBMeta?.name ?? "legacy-country:us-state:state-county:county-gov-legacy-bills-sessionslug-identifierslug_titleslug-support",
    path: supportWibJJSKXsBMeta?.path ?? "support",
    meta: supportWibJJSKXsBMeta || {},
    alias: supportWibJJSKXsBMeta?.alias || [],
    redirect: supportWibJJSKXsBMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/bills/[session-slug]/[identifier-slug]_[title-slug]/support.vue").then(m => m.default || m)
  },
  {
    name: timelinewK86IncvHXMeta?.name ?? "legacy-country:us-state:state-county:county-gov-legacy-bills-sessionslug-identifierslug_titleslug-timeline",
    path: timelinewK86IncvHXMeta?.path ?? "timeline",
    meta: timelinewK86IncvHXMeta || {},
    alias: timelinewK86IncvHXMeta?.alias || [],
    redirect: timelinewK86IncvHXMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/bills/[session-slug]/[identifier-slug]_[title-slug]/timeline.vue").then(m => m.default || m)
  },
  {
    name: _91id_93qJ1yTxxYiNMeta?.name ?? "legacy-country:us-state:state-county:county-gov-legacy-bills-sessionslug-identifierslug_titleslug-versions-id",
    path: _91id_93qJ1yTxxYiNMeta?.path ?? "versions/:id()",
    meta: _91id_93qJ1yTxxYiNMeta || {},
    alias: _91id_93qJ1yTxxYiNMeta?.alias || [],
    redirect: _91id_93qJ1yTxxYiNMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/bills/[session-slug]/[identifier-slug]_[title-slug]/versions/[id].vue").then(m => m.default || m)
  },
  {
    name: indexRnzqwBdnlRMeta?.name ?? "legacy-country:us-state:state-county:county-gov-legacy-bills-sessionslug-identifierslug_titleslug-versions",
    path: indexRnzqwBdnlRMeta?.path ?? "versions",
    meta: indexRnzqwBdnlRMeta || {},
    alias: indexRnzqwBdnlRMeta?.alias || [],
    redirect: indexRnzqwBdnlRMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/bills/[session-slug]/[identifier-slug]_[title-slug]/versions/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93zZzuNQkiPzMeta?.name ?? "legacy-country:us-state:state-county:county-gov-legacy-bills-sessionslug-identifierslug_titleslug-votes-id",
    path: _91id_93zZzuNQkiPzMeta?.path ?? "votes/:id()",
    meta: _91id_93zZzuNQkiPzMeta || {},
    alias: _91id_93zZzuNQkiPzMeta?.alias || [],
    redirect: _91id_93zZzuNQkiPzMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/bills/[session-slug]/[identifier-slug]_[title-slug]/votes/[id].vue").then(m => m.default || m)
  },
  {
    name: indexVFm6j3UBVfMeta?.name ?? "legacy-country:us-state:state-county:county-gov-legacy-bills-sessionslug-identifierslug_titleslug-votes",
    path: indexVFm6j3UBVfMeta?.path ?? "votes",
    meta: indexVFm6j3UBVfMeta || {},
    alias: indexVFm6j3UBVfMeta?.alias || [],
    redirect: indexVFm6j3UBVfMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/bills/[session-slug]/[identifier-slug]_[title-slug]/votes/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: sessionpPsG2j6oC7Meta?.name ?? "legacy-country:us-state:state-county:county-gov-legacy-bills-sessionslug-session",
    path: sessionpPsG2j6oC7Meta?.path ?? "bills/:sessionslug()/session",
    meta: sessionpPsG2j6oC7Meta || {},
    alias: sessionpPsG2j6oC7Meta?.alias || [],
    redirect: sessionpPsG2j6oC7Meta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/bills/[session-slug]/session.vue").then(m => m.default || m),
    children: [
  {
    name: bill_45listiKh7ViJBHaMeta?.name ?? "legacy-country:us-state:state-county:county-gov-legacy-bills-sessionslug-session-bill-list",
    path: bill_45listiKh7ViJBHaMeta?.path ?? "bill-list",
    meta: bill_45listiKh7ViJBHaMeta || {},
    alias: bill_45listiKh7ViJBHaMeta?.alias || [],
    redirect: bill_45listiKh7ViJBHaMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/bills/[session-slug]/session/bill-list.vue").then(m => m.default || m)
  },
  {
    name: sponsorshiprb9UPuFbAJMeta?.name ?? "legacy-country:us-state:state-county:county-gov-legacy-bills-sessionslug-session-sponsorship",
    path: sponsorshiprb9UPuFbAJMeta?.path ?? "sponsorship",
    meta: sponsorshiprb9UPuFbAJMeta || {},
    alias: sponsorshiprb9UPuFbAJMeta?.alias || [],
    redirect: sponsorshiprb9UPuFbAJMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/bills/[session-slug]/session/sponsorship.vue").then(m => m.default || m)
  },
  {
    name: votingHY5E6DLLwYMeta?.name ?? "legacy-country:us-state:state-county:county-gov-legacy-bills-sessionslug-session-voting",
    path: votingHY5E6DLLwYMeta?.path ?? "voting",
    meta: votingHY5E6DLLwYMeta || {},
    alias: votingHY5E6DLLwYMeta?.alias || [],
    redirect: votingHY5E6DLLwYMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/bills/[session-slug]/session/voting.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexzU4Gar8XKiMeta?.name ?? "legacy-country:us-state:state-county:county-gov-legacy-bills",
    path: indexzU4Gar8XKiMeta?.path ?? "bills",
    meta: indexzU4Gar8XKiMeta || {},
    alias: indexzU4Gar8XKiMeta?.alias || [],
    redirect: indexzU4Gar8XKiMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/bills/index.vue").then(m => m.default || m)
  },
  {
    name: searchHk7CDpSxgaMeta?.name ?? "legacy-country:us-state:state-county:county-gov-legacy-bills-search",
    path: searchHk7CDpSxgaMeta?.path ?? "bills/search",
    meta: searchHk7CDpSxgaMeta || {},
    alias: searchHk7CDpSxgaMeta?.alias || [],
    redirect: searchHk7CDpSxgaMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/bills/search.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexBKCcGgkQhMMeta?.name ?? "legacy-country:us-state:state-county:county",
    path: indexBKCcGgkQhMMeta?.path ?? "state\\::state()/county\\::county()",
    meta: indexBKCcGgkQhMMeta || {},
    alias: indexBKCcGgkQhMMeta?.alias || [],
    redirect: indexBKCcGgkQhMMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/country:us/state:[state]/county:[county]/index.vue").then(m => m.default || m)
  },
  {
    name: govHUT1mN2LwnMeta?.name ?? "legacy-country:us-state:state-gov",
    path: govHUT1mN2LwnMeta?.path ?? "state\\::state()/gov",
    meta: govHUT1mN2LwnMeta || {},
    alias: govHUT1mN2LwnMeta?.alias || [],
    redirect: govHUT1mN2LwnMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/country:us/state:[state]/gov.vue").then(m => m.default || m),
    children: [
  {
    name: _91chamber_93ycMw7jfpfwMeta?.name ?? "legacy-country:us-state:state-gov-chamber",
    path: _91chamber_93ycMw7jfpfwMeta?.path ?? ":chamber()",
    meta: _91chamber_93ycMw7jfpfwMeta || {},
    alias: _91chamber_93ycMw7jfpfwMeta?.alias || [],
    redirect: _91chamber_93ycMw7jfpfwMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/country:us/state:[state]/gov/[chamber].vue").then(m => m.default || m)
  },
  {
    name: adminbViwjerw3rMeta?.name ?? "legacy-country:us-state:state-gov-admin",
    path: adminbViwjerw3rMeta?.path ?? "admin",
    meta: adminbViwjerw3rMeta || {},
    alias: adminbViwjerw3rMeta?.alias || [],
    redirect: adminbViwjerw3rMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/country:us/state:[state]/gov/admin.vue").then(m => m.default || m)
  },
  {
    name: latest54dPR9Tn5IMeta?.name ?? "legacy-country:us-state:state-gov-latest",
    path: latest54dPR9Tn5IMeta?.path ?? "latest",
    meta: latest54dPR9Tn5IMeta || {},
    alias: latest54dPR9Tn5IMeta?.alias || [],
    redirect: latest54dPR9Tn5IMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/country:us/state:[state]/gov/latest.vue").then(m => m.default || m)
  },
  {
    name: indexnnsVcZ6yoFMeta?.name ?? "legacy-country:us-state:state-gov-sldl:sldl",
    path: indexnnsVcZ6yoFMeta?.path ?? "sldl\\::sldl()",
    meta: indexnnsVcZ6yoFMeta || {},
    alias: indexnnsVcZ6yoFMeta?.alias || [],
    redirect: indexnnsVcZ6yoFMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/country:us/state:[state]/gov/sldl:[sldl]/index.vue").then(m => m.default || m)
  },
  {
    name: indexy64YNVWFfVMeta?.name ?? "legacy-country:us-state:state-gov-sldu:sldu",
    path: indexy64YNVWFfVMeta?.path ?? "sldu\\::sldu()",
    meta: indexy64YNVWFfVMeta || {},
    alias: indexy64YNVWFfVMeta?.alias || [],
    redirect: indexy64YNVWFfVMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/country:us/state:[state]/gov/sldu:[sldu]/index.vue").then(m => m.default || m)
  },
  {
    name: _91identifier_45slug_93__91title_45slug_93BDhxOZfmaAMeta?.name ?? "legacy-country:us-state:state-gov-legacy-bills-sessionslug-identifierslug_titleslug",
    path: _91identifier_45slug_93__91title_45slug_93BDhxOZfmaAMeta?.path ?? "bills/:sessionslug()/:identifierslug()_:titleslug()",
    meta: _91identifier_45slug_93__91title_45slug_93BDhxOZfmaAMeta || {},
    alias: _91identifier_45slug_93__91title_45slug_93BDhxOZfmaAMeta?.alias || [],
    redirect: _91identifier_45slug_93__91title_45slug_93BDhxOZfmaAMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/bills/[session-slug]/[identifier-slug]_[title-slug].vue").then(m => m.default || m),
    children: [
  {
    name: documentst6qFvom5HFMeta?.name ?? "legacy-country:us-state:state-gov-legacy-bills-sessionslug-identifierslug_titleslug-documents",
    path: documentst6qFvom5HFMeta?.path ?? "documents",
    meta: documentst6qFvom5HFMeta || {},
    alias: documentst6qFvom5HFMeta?.alias || [],
    redirect: documentst6qFvom5HFMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/bills/[session-slug]/[identifier-slug]_[title-slug]/documents.vue").then(m => m.default || m)
  },
  {
    name: supportWibJJSKXsBMeta?.name ?? "legacy-country:us-state:state-gov-legacy-bills-sessionslug-identifierslug_titleslug-support",
    path: supportWibJJSKXsBMeta?.path ?? "support",
    meta: supportWibJJSKXsBMeta || {},
    alias: supportWibJJSKXsBMeta?.alias || [],
    redirect: supportWibJJSKXsBMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/bills/[session-slug]/[identifier-slug]_[title-slug]/support.vue").then(m => m.default || m)
  },
  {
    name: timelinewK86IncvHXMeta?.name ?? "legacy-country:us-state:state-gov-legacy-bills-sessionslug-identifierslug_titleslug-timeline",
    path: timelinewK86IncvHXMeta?.path ?? "timeline",
    meta: timelinewK86IncvHXMeta || {},
    alias: timelinewK86IncvHXMeta?.alias || [],
    redirect: timelinewK86IncvHXMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/bills/[session-slug]/[identifier-slug]_[title-slug]/timeline.vue").then(m => m.default || m)
  },
  {
    name: _91id_93qJ1yTxxYiNMeta?.name ?? "legacy-country:us-state:state-gov-legacy-bills-sessionslug-identifierslug_titleslug-versions-id",
    path: _91id_93qJ1yTxxYiNMeta?.path ?? "versions/:id()",
    meta: _91id_93qJ1yTxxYiNMeta || {},
    alias: _91id_93qJ1yTxxYiNMeta?.alias || [],
    redirect: _91id_93qJ1yTxxYiNMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/bills/[session-slug]/[identifier-slug]_[title-slug]/versions/[id].vue").then(m => m.default || m)
  },
  {
    name: indexRnzqwBdnlRMeta?.name ?? "legacy-country:us-state:state-gov-legacy-bills-sessionslug-identifierslug_titleslug-versions",
    path: indexRnzqwBdnlRMeta?.path ?? "versions",
    meta: indexRnzqwBdnlRMeta || {},
    alias: indexRnzqwBdnlRMeta?.alias || [],
    redirect: indexRnzqwBdnlRMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/bills/[session-slug]/[identifier-slug]_[title-slug]/versions/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93zZzuNQkiPzMeta?.name ?? "legacy-country:us-state:state-gov-legacy-bills-sessionslug-identifierslug_titleslug-votes-id",
    path: _91id_93zZzuNQkiPzMeta?.path ?? "votes/:id()",
    meta: _91id_93zZzuNQkiPzMeta || {},
    alias: _91id_93zZzuNQkiPzMeta?.alias || [],
    redirect: _91id_93zZzuNQkiPzMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/bills/[session-slug]/[identifier-slug]_[title-slug]/votes/[id].vue").then(m => m.default || m)
  },
  {
    name: indexVFm6j3UBVfMeta?.name ?? "legacy-country:us-state:state-gov-legacy-bills-sessionslug-identifierslug_titleslug-votes",
    path: indexVFm6j3UBVfMeta?.path ?? "votes",
    meta: indexVFm6j3UBVfMeta || {},
    alias: indexVFm6j3UBVfMeta?.alias || [],
    redirect: indexVFm6j3UBVfMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/bills/[session-slug]/[identifier-slug]_[title-slug]/votes/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: sessionpPsG2j6oC7Meta?.name ?? "legacy-country:us-state:state-gov-legacy-bills-sessionslug-session",
    path: sessionpPsG2j6oC7Meta?.path ?? "bills/:sessionslug()/session",
    meta: sessionpPsG2j6oC7Meta || {},
    alias: sessionpPsG2j6oC7Meta?.alias || [],
    redirect: sessionpPsG2j6oC7Meta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/bills/[session-slug]/session.vue").then(m => m.default || m),
    children: [
  {
    name: bill_45listiKh7ViJBHaMeta?.name ?? "legacy-country:us-state:state-gov-legacy-bills-sessionslug-session-bill-list",
    path: bill_45listiKh7ViJBHaMeta?.path ?? "bill-list",
    meta: bill_45listiKh7ViJBHaMeta || {},
    alias: bill_45listiKh7ViJBHaMeta?.alias || [],
    redirect: bill_45listiKh7ViJBHaMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/bills/[session-slug]/session/bill-list.vue").then(m => m.default || m)
  },
  {
    name: sponsorshiprb9UPuFbAJMeta?.name ?? "legacy-country:us-state:state-gov-legacy-bills-sessionslug-session-sponsorship",
    path: sponsorshiprb9UPuFbAJMeta?.path ?? "sponsorship",
    meta: sponsorshiprb9UPuFbAJMeta || {},
    alias: sponsorshiprb9UPuFbAJMeta?.alias || [],
    redirect: sponsorshiprb9UPuFbAJMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/bills/[session-slug]/session/sponsorship.vue").then(m => m.default || m)
  },
  {
    name: votingHY5E6DLLwYMeta?.name ?? "legacy-country:us-state:state-gov-legacy-bills-sessionslug-session-voting",
    path: votingHY5E6DLLwYMeta?.path ?? "voting",
    meta: votingHY5E6DLLwYMeta || {},
    alias: votingHY5E6DLLwYMeta?.alias || [],
    redirect: votingHY5E6DLLwYMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/bills/[session-slug]/session/voting.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexzU4Gar8XKiMeta?.name ?? "legacy-country:us-state:state-gov-legacy-bills",
    path: indexzU4Gar8XKiMeta?.path ?? "bills",
    meta: indexzU4Gar8XKiMeta || {},
    alias: indexzU4Gar8XKiMeta?.alias || [],
    redirect: indexzU4Gar8XKiMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/bills/index.vue").then(m => m.default || m)
  },
  {
    name: searchHk7CDpSxgaMeta?.name ?? "legacy-country:us-state:state-gov-legacy-bills-search",
    path: searchHk7CDpSxgaMeta?.path ?? "bills/search",
    meta: searchHk7CDpSxgaMeta || {},
    alias: searchHk7CDpSxgaMeta?.alias || [],
    redirect: searchHk7CDpSxgaMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/bills/search.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexJMwOBgwx8DMeta?.name ?? "legacy-country:us-state:state",
    path: indexJMwOBgwx8DMeta?.path ?? "state\\::state()",
    meta: indexJMwOBgwx8DMeta || {},
    alias: indexJMwOBgwx8DMeta?.alias || [],
    redirect: indexJMwOBgwx8DMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/country:us/state:[state]/index.vue").then(m => m.default || m)
  },
  {
    name: govNYcHyHLDIPMeta?.name ?? "legacy-country:us-state:state-place:place-gov",
    path: govNYcHyHLDIPMeta?.path ?? "state\\::state()/place\\::place()/gov",
    meta: govNYcHyHLDIPMeta || {},
    alias: govNYcHyHLDIPMeta?.alias || [],
    redirect: govNYcHyHLDIPMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/country:us/state:[state]/place:[place]/gov.vue").then(m => m.default || m),
    children: [
  {
    name: _91identifier_45slug_93__91title_45slug_93BDhxOZfmaAMeta?.name ?? "legacy-country:us-state:state-place:place-gov-legacy-bills-sessionslug-identifierslug_titleslug",
    path: _91identifier_45slug_93__91title_45slug_93BDhxOZfmaAMeta?.path ?? "bills/:sessionslug()/:identifierslug()_:titleslug()",
    meta: _91identifier_45slug_93__91title_45slug_93BDhxOZfmaAMeta || {},
    alias: _91identifier_45slug_93__91title_45slug_93BDhxOZfmaAMeta?.alias || [],
    redirect: _91identifier_45slug_93__91title_45slug_93BDhxOZfmaAMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/bills/[session-slug]/[identifier-slug]_[title-slug].vue").then(m => m.default || m),
    children: [
  {
    name: documentst6qFvom5HFMeta?.name ?? "legacy-country:us-state:state-place:place-gov-legacy-bills-sessionslug-identifierslug_titleslug-documents",
    path: documentst6qFvom5HFMeta?.path ?? "documents",
    meta: documentst6qFvom5HFMeta || {},
    alias: documentst6qFvom5HFMeta?.alias || [],
    redirect: documentst6qFvom5HFMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/bills/[session-slug]/[identifier-slug]_[title-slug]/documents.vue").then(m => m.default || m)
  },
  {
    name: supportWibJJSKXsBMeta?.name ?? "legacy-country:us-state:state-place:place-gov-legacy-bills-sessionslug-identifierslug_titleslug-support",
    path: supportWibJJSKXsBMeta?.path ?? "support",
    meta: supportWibJJSKXsBMeta || {},
    alias: supportWibJJSKXsBMeta?.alias || [],
    redirect: supportWibJJSKXsBMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/bills/[session-slug]/[identifier-slug]_[title-slug]/support.vue").then(m => m.default || m)
  },
  {
    name: timelinewK86IncvHXMeta?.name ?? "legacy-country:us-state:state-place:place-gov-legacy-bills-sessionslug-identifierslug_titleslug-timeline",
    path: timelinewK86IncvHXMeta?.path ?? "timeline",
    meta: timelinewK86IncvHXMeta || {},
    alias: timelinewK86IncvHXMeta?.alias || [],
    redirect: timelinewK86IncvHXMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/bills/[session-slug]/[identifier-slug]_[title-slug]/timeline.vue").then(m => m.default || m)
  },
  {
    name: _91id_93qJ1yTxxYiNMeta?.name ?? "legacy-country:us-state:state-place:place-gov-legacy-bills-sessionslug-identifierslug_titleslug-versions-id",
    path: _91id_93qJ1yTxxYiNMeta?.path ?? "versions/:id()",
    meta: _91id_93qJ1yTxxYiNMeta || {},
    alias: _91id_93qJ1yTxxYiNMeta?.alias || [],
    redirect: _91id_93qJ1yTxxYiNMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/bills/[session-slug]/[identifier-slug]_[title-slug]/versions/[id].vue").then(m => m.default || m)
  },
  {
    name: indexRnzqwBdnlRMeta?.name ?? "legacy-country:us-state:state-place:place-gov-legacy-bills-sessionslug-identifierslug_titleslug-versions",
    path: indexRnzqwBdnlRMeta?.path ?? "versions",
    meta: indexRnzqwBdnlRMeta || {},
    alias: indexRnzqwBdnlRMeta?.alias || [],
    redirect: indexRnzqwBdnlRMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/bills/[session-slug]/[identifier-slug]_[title-slug]/versions/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93zZzuNQkiPzMeta?.name ?? "legacy-country:us-state:state-place:place-gov-legacy-bills-sessionslug-identifierslug_titleslug-votes-id",
    path: _91id_93zZzuNQkiPzMeta?.path ?? "votes/:id()",
    meta: _91id_93zZzuNQkiPzMeta || {},
    alias: _91id_93zZzuNQkiPzMeta?.alias || [],
    redirect: _91id_93zZzuNQkiPzMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/bills/[session-slug]/[identifier-slug]_[title-slug]/votes/[id].vue").then(m => m.default || m)
  },
  {
    name: indexVFm6j3UBVfMeta?.name ?? "legacy-country:us-state:state-place:place-gov-legacy-bills-sessionslug-identifierslug_titleslug-votes",
    path: indexVFm6j3UBVfMeta?.path ?? "votes",
    meta: indexVFm6j3UBVfMeta || {},
    alias: indexVFm6j3UBVfMeta?.alias || [],
    redirect: indexVFm6j3UBVfMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/bills/[session-slug]/[identifier-slug]_[title-slug]/votes/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: sessionpPsG2j6oC7Meta?.name ?? "legacy-country:us-state:state-place:place-gov-legacy-bills-sessionslug-session",
    path: sessionpPsG2j6oC7Meta?.path ?? "bills/:sessionslug()/session",
    meta: sessionpPsG2j6oC7Meta || {},
    alias: sessionpPsG2j6oC7Meta?.alias || [],
    redirect: sessionpPsG2j6oC7Meta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/bills/[session-slug]/session.vue").then(m => m.default || m),
    children: [
  {
    name: bill_45listiKh7ViJBHaMeta?.name ?? "legacy-country:us-state:state-place:place-gov-legacy-bills-sessionslug-session-bill-list",
    path: bill_45listiKh7ViJBHaMeta?.path ?? "bill-list",
    meta: bill_45listiKh7ViJBHaMeta || {},
    alias: bill_45listiKh7ViJBHaMeta?.alias || [],
    redirect: bill_45listiKh7ViJBHaMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/bills/[session-slug]/session/bill-list.vue").then(m => m.default || m)
  },
  {
    name: sponsorshiprb9UPuFbAJMeta?.name ?? "legacy-country:us-state:state-place:place-gov-legacy-bills-sessionslug-session-sponsorship",
    path: sponsorshiprb9UPuFbAJMeta?.path ?? "sponsorship",
    meta: sponsorshiprb9UPuFbAJMeta || {},
    alias: sponsorshiprb9UPuFbAJMeta?.alias || [],
    redirect: sponsorshiprb9UPuFbAJMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/bills/[session-slug]/session/sponsorship.vue").then(m => m.default || m)
  },
  {
    name: votingHY5E6DLLwYMeta?.name ?? "legacy-country:us-state:state-place:place-gov-legacy-bills-sessionslug-session-voting",
    path: votingHY5E6DLLwYMeta?.path ?? "voting",
    meta: votingHY5E6DLLwYMeta || {},
    alias: votingHY5E6DLLwYMeta?.alias || [],
    redirect: votingHY5E6DLLwYMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/bills/[session-slug]/session/voting.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexzU4Gar8XKiMeta?.name ?? "legacy-country:us-state:state-place:place-gov-legacy-bills",
    path: indexzU4Gar8XKiMeta?.path ?? "bills",
    meta: indexzU4Gar8XKiMeta || {},
    alias: indexzU4Gar8XKiMeta?.alias || [],
    redirect: indexzU4Gar8XKiMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/bills/index.vue").then(m => m.default || m)
  },
  {
    name: searchHk7CDpSxgaMeta?.name ?? "legacy-country:us-state:state-place:place-gov-legacy-bills-search",
    path: searchHk7CDpSxgaMeta?.path ?? "bills/search",
    meta: searchHk7CDpSxgaMeta || {},
    alias: searchHk7CDpSxgaMeta?.alias || [],
    redirect: searchHk7CDpSxgaMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/bills/search.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: indexJTRdPCrBHdMeta?.name ?? "legacy",
    path: indexJTRdPCrBHdMeta?.path ?? "/legacy",
    meta: indexJTRdPCrBHdMeta || {},
    alias: indexJTRdPCrBHdMeta?.alias || [],
    redirect: indexJTRdPCrBHdMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93DUSAmsNjbfMeta?.name ?? "legacy-interests-slug",
    path: _91slug_93DUSAmsNjbfMeta?.path ?? "/legacy/interests/:slug()",
    meta: _91slug_93DUSAmsNjbfMeta || {},
    alias: _91slug_93DUSAmsNjbfMeta?.alias || [],
    redirect: _91slug_93DUSAmsNjbfMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/interests/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexPmaOJGCYTgMeta?.name ?? "legacy-interests",
    path: indexPmaOJGCYTgMeta?.path ?? "/legacy/interests",
    meta: indexPmaOJGCYTgMeta || {},
    alias: indexPmaOJGCYTgMeta?.alias || [],
    redirect: indexPmaOJGCYTgMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/interests/index.vue").then(m => m.default || m)
  },
  {
    name: iosujxlk0QIXEMeta?.name ?? "legacy-ios",
    path: iosujxlk0QIXEMeta?.path ?? "/legacy/ios",
    meta: iosujxlk0QIXEMeta || {},
    alias: iosujxlk0QIXEMeta?.alias || [],
    redirect: iosujxlk0QIXEMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/ios.vue").then(m => m.default || m)
  },
  {
    name: _91id_93vjhYgJPdeyMeta?.name ?? "legacy-jurisdictions-id",
    path: _91id_93vjhYgJPdeyMeta?.path ?? "/legacy/jurisdictions/:id()",
    meta: _91id_93vjhYgJPdeyMeta || {},
    alias: _91id_93vjhYgJPdeyMeta?.alias || [],
    redirect: _91id_93vjhYgJPdeyMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/jurisdictions/[id].vue").then(m => m.default || m),
    children: [
  {
    name: _91identifier_93sKcUAE25HDMeta?.name ?? "legacy-jurisdictions-id-sessions-identifier",
    path: _91identifier_93sKcUAE25HDMeta?.path ?? "sessions/:identifier()",
    meta: _91identifier_93sKcUAE25HDMeta || {},
    alias: _91identifier_93sKcUAE25HDMeta?.alias || [],
    redirect: _91identifier_93sKcUAE25HDMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/jurisdictions/[id]/sessions/[identifier].vue").then(m => m.default || m)
  },
  {
    name: indexsCKdFFjbyFMeta?.name ?? "legacy-jurisdictions-id-sessions",
    path: indexsCKdFFjbyFMeta?.path ?? "sessions",
    meta: indexsCKdFFjbyFMeta || {},
    alias: indexsCKdFFjbyFMeta?.alias || [],
    redirect: indexsCKdFFjbyFMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/jurisdictions/[id]/sessions/index.vue").then(m => m.default || m)
  },
  {
    name: summaryqvUtPZN1PeMeta?.name ?? "legacy-jurisdictions-id-summary",
    path: summaryqvUtPZN1PeMeta?.path ?? "summary",
    meta: summaryqvUtPZN1PeMeta || {},
    alias: summaryqvUtPZN1PeMeta?.alias || [],
    redirect: summaryqvUtPZN1PeMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/jurisdictions/[id]/summary.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexHa2xfvzuRcMeta?.name ?? "legacy-jurisdictions",
    path: indexHa2xfvzuRcMeta?.path ?? "/legacy/jurisdictions",
    meta: indexHa2xfvzuRcMeta || {},
    alias: indexHa2xfvzuRcMeta?.alias || [],
    redirect: indexHa2xfvzuRcMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/jurisdictions/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93ufq2XnzNlmMeta?.name ?? "legacy-persons-id",
    path: _91id_93ufq2XnzNlmMeta?.path ?? "/legacy/persons/:id()",
    meta: _91id_93ufq2XnzNlmMeta || {},
    alias: _91id_93ufq2XnzNlmMeta?.alias || [],
    redirect: _91id_93ufq2XnzNlmMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/persons/[id].vue").then(m => m.default || m),
    children: [
  {
    name: committeesu396bRQnRYMeta?.name ?? "legacy-persons-id-committees",
    path: committeesu396bRQnRYMeta?.path ?? "committees",
    meta: committeesu396bRQnRYMeta || {},
    alias: committeesu396bRQnRYMeta?.alias || [],
    redirect: committeesu396bRQnRYMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/persons/[id]/committees.vue").then(m => m.default || m)
  },
  {
    name: contactsRVwaj0YgwMeta?.name ?? "legacy-persons-id-contact",
    path: contactsRVwaj0YgwMeta?.path ?? "contact",
    meta: contactsRVwaj0YgwMeta || {},
    alias: contactsRVwaj0YgwMeta?.alias || [],
    redirect: contactsRVwaj0YgwMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/persons/[id]/contact.vue").then(m => m.default || m)
  },
  {
    name: sponsorshipsZsdFexag6WMeta?.name ?? "legacy-persons-id-sponsorships",
    path: sponsorshipsZsdFexag6WMeta?.path ?? "sponsorships",
    meta: sponsorshipsZsdFexag6WMeta || {},
    alias: sponsorshipsZsdFexag6WMeta?.alias || [],
    redirect: sponsorshipsZsdFexag6WMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/persons/[id]/sponsorships.vue").then(m => m.default || m)
  },
  {
    name: summaryGiUVh3ZDCcMeta?.name ?? "legacy-persons-id-summary",
    path: summaryGiUVh3ZDCcMeta?.path ?? "summary",
    meta: summaryGiUVh3ZDCcMeta || {},
    alias: summaryGiUVh3ZDCcMeta?.alias || [],
    redirect: summaryGiUVh3ZDCcMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/persons/[id]/summary.vue").then(m => m.default || m)
  },
  {
    name: votesf9RBBw4E1GMeta?.name ?? "legacy-persons-id-votes",
    path: votesf9RBBw4E1GMeta?.path ?? "votes",
    meta: votesf9RBBw4E1GMeta || {},
    alias: votesf9RBBw4E1GMeta?.alias || [],
    redirect: votesf9RBBw4E1GMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/persons/[id]/votes.vue").then(m => m.default || m)
  }
]
  },
  {
    name: index0Y4btX3OVFMeta?.name ?? "legacy-persons",
    path: index0Y4btX3OVFMeta?.path ?? "/legacy/persons",
    meta: index0Y4btX3OVFMeta || {},
    alias: index0Y4btX3OVFMeta?.alias || [],
    redirect: index0Y4btX3OVFMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/persons/index.vue").then(m => m.default || m)
  },
  {
    name: stateszTYXs8t9u0Meta?.name ?? "legacy-states",
    path: stateszTYXs8t9u0Meta?.path ?? "/legacy/states",
    meta: stateszTYXs8t9u0Meta || {},
    alias: stateszTYXs8t9u0Meta?.alias || [],
    redirect: stateszTYXs8t9u0Meta?.redirect,
    component: () => import("/usr/src/app/src/pages/legacy/states.vue").then(m => m.default || m)
  },
  {
    name: login2t1QMQfxAxMeta?.name ?? "login",
    path: login2t1QMQfxAxMeta?.path ?? "/login",
    meta: login2t1QMQfxAxMeta || {},
    alias: login2t1QMQfxAxMeta?.alias || [],
    redirect: login2t1QMQfxAxMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/login.vue").then(m => m.default || m)
  },
  {
    name: logoutAhPWW22ABKMeta?.name ?? "logout",
    path: logoutAhPWW22ABKMeta?.path ?? "/logout",
    meta: logoutAhPWW22ABKMeta || {},
    alias: logoutAhPWW22ABKMeta?.alias || [],
    redirect: logoutAhPWW22ABKMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: privacyZP7BCLG19uMeta?.name ?? "privacy",
    path: privacyZP7BCLG19uMeta?.path ?? "/privacy",
    meta: privacyZP7BCLG19uMeta || {},
    alias: privacyZP7BCLG19uMeta?.alias || [],
    redirect: privacyZP7BCLG19uMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: problems7MKDDAVU2SMeta?.name ?? "problems",
    path: problems7MKDDAVU2SMeta?.path ?? "/problems",
    meta: problems7MKDDAVU2SMeta || {},
    alias: problems7MKDDAVU2SMeta?.alias || [],
    redirect: problems7MKDDAVU2SMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/problems.vue").then(m => m.default || m)
  },
  {
    name: signupDLuMX3xyLAMeta?.name ?? "signup",
    path: signupDLuMX3xyLAMeta?.path ?? "/signup",
    meta: signupDLuMX3xyLAMeta || {},
    alias: signupDLuMX3xyLAMeta?.alias || [],
    redirect: signupDLuMX3xyLAMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/signup.vue").then(m => m.default || m)
  },
  {
    name: indexjsNmnhAAJRMeta?.name ?? "solutions-bill-boards",
    path: indexjsNmnhAAJRMeta?.path ?? "/solutions/bill-boards",
    meta: indexjsNmnhAAJRMeta || {},
    alias: indexjsNmnhAAJRMeta?.alias || [],
    redirect: indexjsNmnhAAJRMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/solutions/bill-boards/index.vue").then(m => m.default || m)
  },
  {
    name: requestnknz9HbDuYMeta?.name ?? "solutions-bill-boards-request",
    path: requestnknz9HbDuYMeta?.path ?? "/solutions/bill-boards/request",
    meta: requestnknz9HbDuYMeta || {},
    alias: requestnknz9HbDuYMeta?.alias || [],
    redirect: requestnknz9HbDuYMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/solutions/bill-boards/request.vue").then(m => m.default || m)
  },
  {
    name: indexsxghd0jLYqMeta?.name ?? "solutions",
    path: indexsxghd0jLYqMeta?.path ?? "/solutions",
    meta: indexsxghd0jLYqMeta || {},
    alias: indexsxghd0jLYqMeta?.alias || [],
    redirect: indexsxghd0jLYqMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/solutions/index.vue").then(m => m.default || m)
  },
  {
    name: indexNKzH9DQY5mMeta?.name ?? "solutions-insights-portal",
    path: indexNKzH9DQY5mMeta?.path ?? "/solutions/insights-portal",
    meta: indexNKzH9DQY5mMeta || {},
    alias: indexNKzH9DQY5mMeta?.alias || [],
    redirect: indexNKzH9DQY5mMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/solutions/insights-portal/index.vue").then(m => m.default || m)
  },
  {
    name: indexDuczOscAVaMeta?.name ?? "solutions-legislative-associate",
    path: indexDuczOscAVaMeta?.path ?? "/solutions/legislative-associate",
    meta: indexDuczOscAVaMeta || {},
    alias: indexDuczOscAVaMeta?.alias || [],
    redirect: indexDuczOscAVaMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/solutions/legislative-associate/index.vue").then(m => m.default || m)
  },
  {
    name: support8C3Qrkv7S3Meta?.name ?? "support",
    path: support8C3Qrkv7S3Meta?.path ?? "/support",
    meta: support8C3Qrkv7S3Meta || {},
    alias: support8C3Qrkv7S3Meta?.alias || [],
    redirect: support8C3Qrkv7S3Meta?.redirect,
    component: () => import("/usr/src/app/src/pages/support.vue").then(m => m.default || m)
  },
  {
    name: termsA1HF1pYOfSMeta?.name ?? "terms",
    path: termsA1HF1pYOfSMeta?.path ?? "/terms",
    meta: termsA1HF1pYOfSMeta || {},
    alias: termsA1HF1pYOfSMeta?.alias || [],
    redirect: termsA1HF1pYOfSMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: verify_45emailtKKyXmdz9CMeta?.name ?? "verify-email",
    path: verify_45emailtKKyXmdz9CMeta?.path ?? "/verify-email",
    meta: verify_45emailtKKyXmdz9CMeta || {},
    alias: verify_45emailtKKyXmdz9CMeta?.alias || [],
    redirect: verify_45emailtKKyXmdz9CMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/verify-email.vue").then(m => m.default || m)
  },
  {
    name: _91id_93WvJqDxKGk1Meta?.name ?? "widgets-id",
    path: _91id_93WvJqDxKGk1Meta?.path ?? "/widgets/:id()",
    meta: _91id_93WvJqDxKGk1Meta || {},
    alias: _91id_93WvJqDxKGk1Meta?.alias || [],
    redirect: _91id_93WvJqDxKGk1Meta?.redirect,
    component: () => import("/usr/src/app/src/pages/widgets/[id].vue").then(m => m.default || m)
  },
  {
    name: demo3KqakIzaiwMeta?.name ?? "widgets-demo",
    path: demo3KqakIzaiwMeta?.path ?? "/widgets/demo",
    meta: demo3KqakIzaiwMeta || {},
    alias: demo3KqakIzaiwMeta?.alias || [],
    redirect: demo3KqakIzaiwMeta?.redirect,
    component: () => import("/usr/src/app/src/pages/widgets/demo.vue").then(m => m.default || m)
  }
]