/* eslint-disable node/prefer-global/process */
import type { User } from 'firebase/auth'
import { getAuth, signInAnonymously, signOut } from 'firebase/auth'
import { useApolloClient } from '@vue/apollo-composable'
import { useMainStore } from '~/store'
import type { ActiveUserFragment } from '~/gql/graphql'
import { GetMeDocument } from '~/gql/graphql'

// middleware/auth.ts

const printMessages = false

export async function ensureFirebaseUser(
    anonymousAllowed: boolean = false,
): Promise<User | null> {
    let firebaseUser = await getCurrentUser() as User | null

    if (firebaseUser == null && anonymousAllowed) {
        printMessage('No user, signing in anonymously')
        firebaseUser = await signInAnonymously(getAuth()).then((userCredential) => {
            // Signed in..
            printMessage('Signed in anonymously')
            const user = userCredential.user
            return user
        }).catch((error) => {
            const errorCode = error.code
            const errorMessage = error.message
            printMessage(`Error: ${errorMessage}${errorCode}`)
            return null
        })
    }
    return firebaseUser
}

export async function ensureApolloInitialized(firebaseUser: User | null): Promise<boolean> {
    if (firebaseUser == null) {
        const { onLogout } = useApollo()
        await onLogout('auth')
        return true
    }

    const firebaseToken = await firebaseUser.getIdToken()

    const { getToken } = useApollo()

    const apolloToken = await getToken('auth')
    printMessage(`apollo token ${apolloToken}`)
    printMessage(`firebase token ${firebaseToken}`)
    printMessage(`are equal ${firebaseToken === apolloToken}`)

    if (firebaseToken !== apolloToken) {
        console.log('new token updated')
        const { onLogin } = useApollo()
        await onLogin(firebaseToken, 'auth')
        return true
    }
    return false
}

interface AuthRequirements {
    requireOrganizationAccount?: boolean
    requireEmailVerified?: boolean
}


export type CiviqaUserResult = {
    noOrganizationAccount?: boolean
    notEmailVerified?: boolean
    notRegistered?: boolean
    message?: string
    civiqaUser?: ActiveUserFragment
}


export async function ensureCiviqaUserOnClient(requirements?: AuthRequirements): Promise<CiviqaUserResult> {
    if (process.client) {
        let civiqaUser = useMainStore().civiqaUser;
        if (civiqaUser) {
            if (
                ensureAuthRequirements(civiqaUser, requirements).civiqaUser == civiqaUser
            ) {
                useMainStore().civiqaUser = civiqaUser;
                return { civiqaUser: civiqaUser };
            }
        }
        try {
            civiqaUser = await getCiviqaUser();
            const result = ensureAuthRequirements(civiqaUser, requirements);
            if (result.civiqaUser == civiqaUser) {
                useMainStore().civiqaUser = civiqaUser;
                return { civiqaUser: civiqaUser };
            }
            return result;
        } catch (error: any) {
            return { message: error.message };
        }
    }
    return { message: 'ensureCiviqaUserOnClient called on server' }
}

async function getCiviqaUser(): Promise<ActiveUserFragment> {
    const { resolveClient } = useApolloClient()
    const client = resolveClient('auth')

    let result;
    try {
        result = await client.query(
            { query: GetMeDocument, variables: { input: {} }, fetchPolicy: 'network-only' },
        )
    } catch (error: any) {
        if (error.networkError && error.networkError.result && error.networkError.result.detail) {
            throw Error(error.networkError.result.detail)
        }
        throw error
    }
    if (result.data?.me) {
        return result.data.me
    }   
    if (result.errors) {
        throw Error(result.errors[0].message)
    }
    throw Error('An error occurred. Please try again later.')
}

function ensureAuthRequirements(civiqaUser: ActiveUserFragment, requirements?: AuthRequirements): CiviqaUserResult {
    if (civiqaUser.email == null) {
        return { notRegistered: true }
    }
    if (requirements?.requireEmailVerified) {
        if (civiqaUser.isFirebaseAnonymous) {
            printMessage('anonymous user not allowed')
            return { notEmailVerified: true }
        }
    }
    if (requirements?.requireOrganizationAccount) {
        if (!civiqaUser.account) {
            printMessage('account required')
            return { noOrganizationAccount: true }
        }
    }
    return { civiqaUser: civiqaUser };
}

function printMessage(message: string) {
    if (!printMessages)
        return
    console.log((process.server ? 'server: ' : 'client: ') + message)
}
