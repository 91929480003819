import { defineStore } from 'pinia'
import type { ActiveUserFragment, BillboardFragment, BillboardSummaryFragment } from '~/gql/graphql'
import { StateOption } from '~/pages/components/stateOptions'

export interface BillieContext {
    billId: string
    billTitle: string
}

export const useMainStore = defineStore('main', {
    state: () => ({
        civiqaUser: null as ActiveUserFragment | null,
        divisionFragments: {} as Map<string, string>,
        billieContext: null as BillieContext | null,
        chatOpen: false,
        firebaseToken: null as string | null,
        searchSelectedBillIds: [] as string[],
        searchVisibleStates: [] as StateOption[],
        searchSelectedStates: [] as StateOption[],
        currentBillboard: null as BillboardSummaryFragment | null,
        currentBillboardSection: null as string | null,
    }),
})
