import validate from "/usr/src/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/usr/src/app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/usr/src/app/src/middleware/auth.ts"),
  "auth-require-account": () => import("/usr/src/app/src/middleware/authRequireAccount.ts"),
  "auth-require-verified-email": () => import("/usr/src/app/src/middleware/authRequireVerifiedEmail.ts")
}